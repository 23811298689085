
.card5_body{
    width: 100vw;
    min-height: 50vh;
    overflow: hidden;
    position: relative;
    background-color: rgba(20, 17, 58, 0);
}

.card5_img{
    position: absolute;
    object-fit: cover;
    width : 100%;
    height: 100%;
}

.card5_title{
    text-indent: 10%;
    font-size: 5rem;
    color: #ffffff;

}

.card5_titleBG{
    margin-top: 10rem;
    // background-color: rgba(31, 27, 89, .3);
    width: 50%;
    // border-radius: 0.5rem;
    border-top-right-radius: 5rem;
    border-block-end-style:groove;
}

.card5_titleBGRight{
    margin-top: 10rem;
    margin-left: 50%;
    // background-color:rgba(41, 72, 132, 0.3);
    width: 50%;

    // border-radius: 0.5rem;
    border-top-left-radius: 5rem;
    // border-block-start-style:groove;
    border-block-end-style:groove;
}

.card5_description{
    font-size: 2rem;
    color: #ffffff;
    text-align: right;
    width: 50%;
    padding-top: 0.5rem;
    padding-right: 1rem;
}

.card5_descriptionRight{
    font-size: 1.3rem;
    margin-left: 50%;
    color: #ffffff;
    text-align: justify;
    width: 50%;
    padding-right: 1rem;
    padding-top: 0.5rem;

}

@media only screen and (max-width: 768px) {
    .card5_title{
        font-size: 2rem;
    }
    .card5_description{
        font-size: 1rem;
    padding-top: 0.5rem;

    }

    .card5_descriptionRight{
    padding-top: 0.5rem;

        font-size: .8rem;
        margin-left: 50%;
        color: #ffffff;
        text-align: justify;
        width: 50%;
    }
}