* {
  box-sizing: border-box;
}
.contain {
  width: 100vw;
  /* height: 100vh; */
  margin: 0;
  padding: 0;
}
.scroll {
  scroll-snap-type: y proximity;
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  top: 0;
  left: 0;
}
.scroll > div {
  scroll-snap-align: start;
}
.Button {
  margin: auto;
}
.Home-Projects {
  scroll-snap-stop: always;
}
.Home-About {
  scroll-snap-stop: always;
}
h1 {
  font-size: 3em;
  letter-spacing: -3px;
  line-height: 0.7em;
}
span {
  color: font;
}
.Welcome {
  display: flex;
  flex-direction: column;
  /* width: 40%; */
  /* overflow: hidden; */
  /* margin-left: 10%; */
  /* padding-left: 5%;
  padding-right: 5%; */
}
.Canvas-Container {
  /* margin-top: 20rem; */
  /* margin: auto; */
  /* width: 1000px; */
  height: 50vh;
  width: 50vw;
  /* border-radius: 8px; */
  /* margin-top: 20vh;
  margin-bottom: 10vh;
  margin-right: 5vw; */
}
.textDescription {
  z-index: 1;
}
.Welcome-Title {
  position: relative;
  text-align: center;
  z-index: 100;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #ffffff;
}
.Welcome-Para {
  text-align: justify;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #ffffff;
}
.Home-Welcome{
  /* height: fit-content; */
  min-height: 100vh;
  max-height: fit-content;
  /* width: 80vw; */
  display: flex;
  margin: auto;
}
.Home-Projects{
  position:relative;
  /* position: absolute; */
  z-index: 1;
  /* height: fit-content; */
  min-height: 50vh;
  max-height: fit-content;
  width: 80vw;
  margin: auto;
  /* padding-top: 5rem; */
  color: #ffffff;
}
.Home-About{
  /* height: fit-content; */
  min-height: 100vh;
  max-height: fit-content;
  width: 80vw;
  margin: auto;
  color: #ffffff;
}
.home-contact-table{
  /* min-width: 50vw; */
  /* max-width: 100vw; */
  /* white-space: nowrap; */
  color: #ffffff;
  background-color: transparent;
}
.home-contact-table-row{
  /* border:1px solid white; */
}
.home-contact-table-row td {
  text-align: center;
  border:none;
  margin: auto;
  /* margin: auto; */
  /* padding: 5px; */
  color: #ffffff;
}
/* style={{marginTop:"2vh",marginBottom:"2vh",width:"10vw",height:"5vw",borderRadius:"8px",background:"transparent"}} */
.contact-button{
  margin: 2rem;
  height:50px;
  width: 100px;
  background-color: transparent;
  color: #ffffff;
  border: 1px solid white;
  border-radius: 0.5rem;
  transition: 0.4s;
}
.contact-button:hover{
  height: 60px;
  width: 120px;
  border-radius: 1rem;
  background-color: #0665ff;
  transition: 0.4s;
}

.home-contact-table-row input{
  min-height: 2rem;
  /* padding: 5px; */
  background-color: transparent;
  /* border-radius: 0.5rem; */
  /* border: 1px solid #ffffff; */
  border: none;
  border-bottom: 1px solid white;
  color: #ffffff;
}
.home-contact-table-row input:focus{
  transition: 0.4s;
  outline: none;
  border-color: #0665ff;
}
.home-contact-table-row select:focus{
  transition: 0.4s;
  outline: none;
  border-color: #0665ff;
}
.home-contact-table-row textarea:focus{
  transition: 0.4s;
  outline: none;
  border-color: #0665ff;
}
.home-contact-table-row input::placeholder{
  text-align: center;
  color: #c0c0c0;
}
.home-contact-table-row select{
  /* border-radius: 0.5rem;
  border: transparent; */
  border: none;
  border-bottom: 1px solid white;
  color: #ffffff;
}
.home-contact-table textarea{
  color: #ffffff;
  border-radius: 0.5rem;
  min-height: 150px;
  border: 1px solid #ffffff;
  width: 100%;
  background-color: transparent;
}
tr td:first-child{
  padding-left: 20px;
}
tr td:last-child{
  border: none;
  padding-right: 20px;
  /* padding: 5px; */
  /* width: 90%; */
}
@media screen and (max-width: 640px) {
  .home-contact-table{
    max-width: 100vw;
  }

  tr td{
    width: 90%;
  }

  .about_Title{
    font-size: x-large;
  }
  .Home-About-Big{
    display: none;
  }
  .home-contact-table-row{
    display: flex;
    flex-direction: column;
    border:none;
  }
  .Home-Welcome {
    flex-direction: column;
    /* margin: auto; */
  }
  .Welcome-Para{
    width: 80vw;
    margin: auto;
    font-size:large;
  }
  .Welcome-Title{
    font-size: 1.5rem;
    margin-top: 20vh;
    /* width: 60vw; */
  }
  .Canvas-Container{
    margin: auto;
    margin-bottom: 10vh;
  }
}
@media (min-width: 640px) and (max-width: 1000px) {
  .home-contact-table{
    max-width: 100vw;
  }
  tr td{
    width: 90%;
  }
  .home-contact-table-row {
    width: 40%;
  }
  .about_Title{
    font-size: x-large;
  }
  .Home-About-Big{
    display: none;
  }
  .home-contact-table-row{
    align-content: space-between;
    align-items:center;
    /* display: flex;
    flex-direction: column; */
    border:none;
  }
  .Home-Welcome {
    flex-direction: column;
  }
  .Welcome-Para{
    font-size: larger;
    margin: auto;
    width: 80vw;
  }
  .Welcome-Title{
    font-size: 2rem;
    margin-top: 20vh;
  }
  .Canvas-Container{
    margin: auto;
    margin-bottom: 10vh;
  }
}
@media (min-width: 1000px) and (max-width: 1400px) {
  .home-contact-table{
    max-width: 50vw;
  }
  tr td{
    width: 45%;
  }
  .home-contact-table-row {
    width: 40%;
  }
  .Home-About-Small{
    display: none;
  }
  .home-contact-table-row{
    border:none;
  }
  .Home-Welcome {
    flex-direction: row;
    margin: auto;
    width: 80%;
  }
  .Welcome-Title{
    font-size: 2.5rem;
  }
  .Welcome-Para{
    font-size: x-large;
  }
  .Canvas-Container{
    margin: auto;
  }
}
@media (min-width: 1400px) and (max-width: 1920px) {
  .home-contact-table{
    max-width: 50vw;
  }
  tr td{
    width: 45%;
  }
  .home-contact-table-row {
    width: 40%;
  }
  .home-contact-div{
    margin: auto;
    width: 80vw;
  }
  .about_Title{
    font-size: 2rem;
  }
  .about_picText1{
    font-size: 1.5rem;
  }
  .Home-About-Small{
    display: none;
  }
  .home-contact-table-row{
    border:none;
  }
  .Home-Welcome {
    flex-direction: row;
    margin: auto;
    width: 60%;
    overflow:visible;
  }
  .Welcome-Title{
    font-size: 3rem;
  }
  .Canvas-Container{
    margin: 5rem;
    margin-top: 15rem;
  }
  .Welcome-Para{
    font-size: xx-large;
  }
}
body{
  /* background-color: #343434; */
  /* background-color:#2A1D6B; */
  /* 使用CSS创建相同的线性渐变 */
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
    background-size: cover; /* 确保背景覆盖整个页面 */

    /* background: linear-gradient(121.26deg, #0723A7 9.49%, #587CF6 72.53%, #ED662D 99.8%); */

  

}
body::before{
  content: "";
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  background: linear-gradient(121.26deg, #0723A7 9.49%, #587CF6 72.53%, #ED662D 99.8%);
}

.home-contact-table-row input:-internal-autofill-selected {
  background-color: rgba(70, 90, 126, 0.4) !important;
}

.header-logo-container{
  display: flex;
  flex-direction: row;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 0.5rem;
  /* visibility: hidden; */
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: #ED662D; 
  border: 1px solid black;
  border-radius: 0.2rem;
}

::-webkit-scrollbar-thumb:hover {
  background: #ED662D; 
}