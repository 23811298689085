.leftCornerText { 
    position: absolute;
    color: white;
    bottom: 1rem;
    left: 1rem;
    opacity: 1;
    font-size: 1rem;
}

.rightCornerText { 
    position: absolute;
    color: #ffffff;
    bottom: 1rem;
    right: 1rem;
    opacity: 1;
    font-size: 1rem;
}
.middleText{
    height: 50%;
    /* display: flex; */
    align-items: center;
    justify-content: center;
}

.cardProfile {
    background: #191c29;
    /* width: var(--card-width);
    height: var(--card-height); */
    width: 28rem;
    height: 15rem;
    display: flex;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    position: relative;
    border-radius: 0.5rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.5rem;
    color: rgb(88 199 250 / 0%);
    cursor: pointer;
    transition: all 0.4s;
    z-index: 1;
    margin: 50px 0px;
}

.cardProfile1 {
    background: #191d31;
    width: 98%;
    height: 98%;
    position: absolute;
    border-radius: 0.5rem;
    flex-direction: column;
    margin: 0;
    top: 1%;
    left: 1%;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    display: flex;
    font-size: 1rem;
    color: rgba(26, 163, 226, 0);
    cursor: pointer;
    z-index: 0.1;
    pointer-events: auto;
}

.cardProfile:hover {
    color: rgb(88 199 250 / 100%);
    transform: scale(1.5);
    transform-origin: center;
    transition: all 0.4s ease-in;
    animation: spin 2.5s linear infinite;
}

.cardProfile:hover:before{
    transform-origin: center;
    animation: spin 2.5s linear infinite;
}

.cardProfile:hover:after {
    transform-origin: center;
    filter: blur(calc(var(--card-height) / 50));
    background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #4e00c2);
    animation: spin 2.5s linear infinite;
}
/* bgWithEffect */
.cardProfile::before {
    content: "";
    padding: 1rem;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 0.5rem;
    background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #4e00c2);
    position: absolute;
    opacity: 1;
    transition: opacity 0.4s;
    offset-anchor: center;
}
/* Ambient effect */
.cardProfile::after {
    position: absolute;
    content: "";
    top: calc(var(--card-height) / 6);
    /* left: 0;
    right: 0; */
    z-index: -1;
    height: 100%;
    width: 100%;
    /* margin: 0 auto; */
    transform: scale(0.6);
    offset-anchor: center;
    opacity: 1;
}

.app{
    flex-direction: var(--direction);
    justify-content: center;
    align-content: center;
    align-items: center;
}

.textDescription{
    padding: 1.5rem;
    margin: auto;
    text-align: justify;
    color: #ffffff;
}

@keyframes spin {
    0% {
        --rotate: 0deg;
    }

    100% {
        --rotate: 360deg;
    }
}

@media screen and (max-width: 640px){
    .app{
        flex-direction: column;
    }

    .cardProfile{
        width: 18rem;
        height: 10rem;
        justify-self: center;
        margin: auto;
    }
}

@media (min-width: 786px) and (max-width: 1369px){
    .app{
        flex-direction: column;
    }

    .cardProfile{
        width: 35rem;
        height: 18rem;
        margin: auto;
    }
}

@media (min-width: 640px) and (max-width: 786px){
    .app{
        flex-direction: column;
    }

    .cardProfile{
        width: 25rem;
        height: 13rem;
        margin: auto;
    }
}