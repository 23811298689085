.bgColor {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: -235px;
  // background-color: #6078E5;
  z-index: 0;
  // padding: 30px;
}

.waveSpacerBottom {
  height: 430px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: -40px;
  margin-bottom: -10rem;
  z-index: -5;
  transform: scaleY(0.3) translateY(-100%) rotateZ(180deg);
  // margin-bottom: -160px;
}

.waveSpacerTop {
  // aspect-ratio: 960/300;
  height: 430px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -5;
  transform: scaleY(.3) translateY(100%);
  // transform-origin: bottom;
}

.waveSpacerTopContact {
  // aspect-ratio: 960/300;
  height: 430px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  transform: scaleY(0.3) translateY(-60%);
  // transform-origin: bottom;
}
.textContainer{
  text-indent: 5rem;
}

.waveLayer-bottom {
  // background-image: url("../assets/images/layered-waves-haikei-top.svg");
}

.waveLayer-top {
  // background-image: url("../assets/images/layered-waves-haikei-top.svg");
}

.about-mainContainer {
  @media (max-width: 640px) {
    width: 100%;
  }
  width: 80%;
  margin: auto;
  margin-top: 0;
}

.about_imgMask {
  // background-image: url("../assets/images/blob-haikei.png");
  overflow: hidden;
  background-repeat: no-repeat;
}

.about_mainPicture {
  align-self: center;
  justify-self: center;
  margin: 0;
  padding: 0;
  scale: 1.2;
  mask: url("../assets/images/blobs/Untitled-3-03.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
}

.about_Title1 {
  // background-image: url("../assets/images/blobs/Untitled-3-03.svg");
  opacity: 1;
  height: 15vh;
  min-width: 35rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #00000000;
  border-top-left-radius: 20rem;
  border-radius: 20rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 48px;
  margin-bottom: 5rem;
  // background-position-y: -20px;
  .o{
    color: #ED662D;
    margin-left: 15px;
  }
}
.bigFont{
  font-size: 85px;
}

.o1{
  color: rgb(94, 9, 253);
  text-indent: 1rem;
}

.o0{
  color: transparent;
  background: linear-gradient(135grad, #7955FD 0%, #FB79FE 50%, #CF00AD 100%);
  text-indent: 1rem;
  background-clip: text
}

.about_picText {
  // background-image: url("../assets/images/blobs/Untitled-3-03.svg");
  opacity: 1;
  padding-top: 10%;
  height: 90vh;
  width: 90vh;
  text-indent: 3rem;
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;

  // text-align: center;
  // padding-top: 50px;
  // background-position-x: -50px;
  // background-position-y: -20px;
}

.in-section-wave {
  position: absolute;
  left: 0%;
  top: 0%;
  height: 100%;
  width: 100%;
  background-position: -10rem -5rem;
  background-image: url(../assets/images/line/1.1-12.png);
}
.reverse-in-section-wave {
  position: absolute;
  left: 0%;
  top: 0%;
  height: 100%;
  width: 100%;
  background-position: -10rem -5rem;
  background-image: url(../assets/images/line/1.1-12.png);
  transform: rotateZ(180deg);
}
.wave-grid {
  position: absolute;
  left: 0%;
  top: 55%;
  width: 100%;
  opacity: 0.3;
}

.simple-wave {
  position: absolute;
  left: 0%;
  top: -0%;
  height: 110%;
  width: 110%;
  background-image: url("../assets/images/line/1.1-01.png");
  background-position: -5rem -0rem;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
  z-index: 0;
}
.simple-wave-2 {
  position: absolute;
  left: 0%;
  top: -5rem;
  height: 80%;
  width: 100%;
  background-image: url("../assets/images/line/1.1-12.png");
  background-position: -0rem -0rem;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
  z-index: 0;
}
