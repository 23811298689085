@property --rotate {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
}
 
@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu-Medium.ttf') format('truetype');
}
 
* {
    font-family: 'Ubuntu';
    letter-spacing: normal;
}
 
body {
    font-family: 'Ubuntu', sans-serif;
}
 
:root {
    --card-height: 20rem;
    --card-width: calc(var(--card-height) / 1.5);
    --cardSquare-height: 15rem;
    --cardSquare-width: calc(var(--cardSquare-height) / 1.0);
    --card4-height: 16rem;
    --card4-width: calc(var(--card4-height) / 1.2)
}
 
.card4_outer {
    background: #191c29;
    width: var(--card4-width);
    height: var(--card4-height);
    position: relative;
    border-radius: 0.5rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    font-size: 0.5rem;
    color: rgb(88 199 250 / 0%);
    cursor: pointer;
    z-index: 1;
    transition: all 0.5s ease-in;
}
 
.card4_inner {
    overflow: hidden;
    background: #191d31;
    min-width: auto;
    min-height: auto;
    width: var(--card4-width);
    height: var(--card4-height);

    position: relative;
    align-self: flex-start;
    transform-origin: top;
    border-radius: 0.5rem;
    flex-direction: column;
    display: flex;

    font-size: 1rem;
    color: rgb(88 199 250 / 0%);
    cursor: pointer;
    z-index: 1;
    transition: all 0.5s ease-in;
}
 
.card4_outer:hover {
    color: rgb(88 199 250 / 100%);
    height: 28rem;
}
 
.card4_outer:hover > .card4_inner{
    height: 28rem;
}
 
.card4_outer:hover .card4_description{
    transition-duration: 500ms;
    background-color: #191d31;
    border-radius: 0.5rem;;
    // transition: all 0.5s;
}
 
.card4_outer:hover .card4_extraDescription{
    visibility: visible;
    width: 100%;
    height: 100%;
    opacity: 1;
}

.card4_outer:hover .playStorePic{
    position: absolute;
    // bottom: -10px;
    opacity: 1;
    
}
 
.card4_inner:hover:before,
.card4_inner:hover:after{
    animation: none;
    // transform: scale(1);
}
 
.card4_outer:hover:before{
    animation: spin 2.5s linear infinite;
}

.card4_outer:hover:after {
    animation: spin 2.5s linear infinite;
    filter: blur(calc(var(--card4-height) / 50));
    transform: scale(1) ;
    transform-origin: center;
    background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #4e00c2); 
    // background-image: url(../assets//images/filter/Blureffect3.png);
    // background-position: -1rem 1.5rem;
    // background-size: cover;
}
 
.card4_outer::before {
    content: "";
    padding: 1rem;
    width: 102%;
    height: 102%;
    z-index: -1;
    border-radius: 0.5rem;
    background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #4e00c2);
    position: absolute;
    top: -1%;
    left: -1%;
    opacity: 1;
}
 
.card4_outer::after {
    position: absolute;
    content: "";
    // top: calc(var(--card4-height) / 6);
    left: 0.5;
    right: 0.5;
    top: 0.5;
    bottom: 0.5;
    z-index: -1;
    height: 103%;
    width: 102%;
    margin: 0 auto;
    transform: scale(0.5);
    opacity: 1;
}
 
.card4_description {
    color: rgb(28, 192, 184);
    overflow-wrap: break-word;
    inline-size: 100%;
    padding: 0.5rem;
    transform: all 0.5s;
}
 
.card4_title {
    padding-top: 0.5rem;
    // padding-left: 1rem;
    font-size: 1.3rem;
    align-self: center;
    color: rgb(28, 192, 184);
}
 
.card4_play {
    color: rgb(28, 192, 184);
    position: relative;
    // padding-left: 4rem;
    // padding-right: 4rem;
    // padding-bottom: 0.2rem;
    // padding-top: 0.2rem;
    // bottom: 0.1rem;
    font-weight: 700;
    font-size: 1.5rem;
    margin: 10px;
    // border: 2px solid #68a5cb;
    border-radius: 0.5rem;
    // background-color: #2D1E6B;
}
 
.card4_extraDescription{
    position: relative;
    color: rgb(28, 192, 184);
    padding-right: .5rem;
    padding-left: 0.5rem;
    text-align: justify;
    // opacity: 0;
    transition: all 0.5s
}
.playStorePic{
    position: absolute;
    bottom: -10px;
    opacity: 0;
    // position: bottom;
    // bottom: auto;
    width: 70%;
    left: 12.5%;
    // height: 100%;
    // bottom: 0.1rem;
    transition: all 0.5s;
}
 
/* @media only screen and (max-width: 768px) {
    [class*="card"] {
        min-width:
    }
} */
 
@keyframes spin {
    0% {
        --rotate: 0deg;
    }
 
    100% {
        --rotate: 360deg;
    }
}

@media only screen and (max-width: 768px) {
    .card4_outer:hover{
        animation: none;

    }
    .card4_inner:hover{
        animation: none;
    }

    .card4_inner:hover:before,
    .card4_inner:hover:after{
        animation: none;

    }
    .card4_outer:hover:after
    {
        animation: none;
        background-image: none;
        filter: none;
    }
}
