$color-purple: #8b5cf6;
$color-pink: #ec4899;
$color-gray: #9ca3af;
$color-black: #1f2937;
$card-size: 23rem;

$card-height: 100%;

$arrowBtn-size: 4rem;

body {
  align-items: center;
  justify-content: center;
}

* {
  box-sizing: border-box;
}

.corousel-app {
  overflow-x: hidden;
  overflow-y: hidden;
  // background-color: red;
  padding: 2rem;
  width: 120%;
  margin-left: -10%;
  height: calc($card-size*1.3);
}

.corousel-carousel {
  // max-height: fit-content;
  // min-height: 100vh;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: calc($card-size/1.5);
  height: calc($card-size);
  //   perspective: 500px;
  transform-style: preserve-3d;
}

.corousel-card-container {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-left: 17.5px;
  transform:
    rotateY(calc(var(--offset) * 27deg)) scaleY(calc(1 + var(--abs-offset) * -0.4)) scaleX(calc(1 + var(--abs-offset) * -0.4)) translateZ(calc(var(--abs-offset) * -100rem)) translateX(calc(var(--direction) * -13rem));
  transition: all (var(--duration)) ease-out;
}

.corousel-nav {
  height: $arrowBtn-size;
  width: $arrowBtn-size;
  color: white;
  font-size: 5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -20%;
  z-index: 2;
  cursor: pointer;
  user-select: none;
  background: unset;
  border: unset;
  background-color: rgb(34, 162, 212);
  border-radius: $arrowBtn-size;
  // margin-bottom: 100px;
}

.corousel-left {
  transform: rotateZ(180deg);
  margin-bottom: 2rem;
  margin-left: 1rem;
  z-index: 5;
}

.corousel-right {
  // position: relative;
  margin-bottom: 2rem;
  margin-left: 10rem;
  // transform: translateX(250%);
  z-index: 5;
}

.corousel-right:hover {
  animation: scaler 1s infinite linear;
  // z-index: 100;
}

.corousel-left:hover {
  animation: scaler 1s infinite linear;
  // z-index: 100;
}

@keyframes scaler {
  0% {
    scale: 1.0;
    z-index: 5;
  }

  50% {
    scale: 1.1;
    z-index: 5;

  }

  100% {
    scale: 1.0;
    z-index: 5;

  }
}

.corousel-arrow {
  height: calc($arrowBtn-size * 0.6);
  width: calc($arrowBtn-size * 0.6);
}

.carousel-caMain {
  height: 120%;
  // background-color: #ec4899;
}