$profilePad: 2rem;
@media (max-width: 500px) {
  .haku-profile-main-parent {
    height: 300px;
    width: 300px;
  }
  .haku-profile-main-text {
    font-size: 15px;
  }
  .haku-profile-main-title {
    font-size: 20px;
  }
}
@media (min-width: 500px) {
  .haku-profile-main-parent {
    height: 400px;
    width: 400px;
  }
  .haku-profile-main-text {
    font-size: 18px;
  }
  .haku-profile-main-title {
    font-size: 25px;
  }
}
.haku-profile-main-parent {
  padding: $profilePad;
  background-color: #000000;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  &:hover {
    .haku-profile-main {
      .haku-profile-main-text {
        transition: all 0.8s;
        transform: translateY(0);
        transition-delay: 0.6s;
        opacity: 1;
      }
      .haku-profile-main-title {
        transition: all 0.8s;
        transition-delay: 0.3s;
        transform: translateY(0);
        opacity: 1;
      }
      .haku-profile-img-mask {
        opacity: 0.5;
      }
      &::before,
      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
    .haku-profile-img-mask {
      opacity: 0.8;
    }
    
  }
}

.haku-profile-main-blue{
  color: #68a5ca;
  .haku-profile-img-mask{
    background-color: #14113a;
  }
}
.haku-profile-main-black{
  color: #ffffff;
  .haku-profile-img-mask{
    background-color: #000000;
  }
}






.haku-profile-main {
  height: 100%;
  width: 100%;
  position: relative;
  align-items: center;
  transition: transform 0.4s;
  &:active {
    border-width: 2px;
  }
  &:after,
  &:before {
    content: "";
    position: absolute;
    left: 0;
    display: inline-block;
    height: 100%;
    width: 100%;
    margin-top: 0px;
    opacity: 0;
    transition: all 0.4s;
  }
  &:after {
    border-bottom: 2px solid;
    border-top: 2px solid;
    transform: scale(0, 1);
  }
  &::before {
    border-left: 2px solid;
    border-right: 2px solid;
    transform: scale(1, 0);
  }
  
}

.haku-profile-main-title {
  text-align: center;
  opacity: 0;
  position: relative;
  transition: all 0.4s;
  transform: translateY(100%);
  margin-bottom: 10px;
}

.haku-profile-main-text {
  text-align: justify;
  opacity: 0;
  position: relative;
  transition: all 0.4s;
  transform: translateY(100%);
}

.haku-profile-text-parent {
  padding: 1rem;
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  color: #ffffff;
}

.haku-profile-img {
  width: calc(100%);
  height: calc(100%);
  left: 0;
  top: 0;
  position: absolute;
  object-fit: cover;
}

.haku-profile-img-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  
  opacity: 0;
  transition: all 0.4s;
}
