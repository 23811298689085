.haku-home-about-p{
    height: 30vh;
    width: 30vw;
    display: flex;
    padding: 1rem;
    justify-content: center;
}
.haku-home-about-canvas-parent{
    height: 100%;
    width: 100vw;
}