.footer-mainContainer {
  width: 80%;
  margin: 0 auto;
  min-height: 100px;
  color: #ffffff;
}
.footer-datas {
  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
  @media (min-width: 900px) {
    flex-direction: row;
  }
  display: flex;
}
.footer-copyright {
  position: relative;
  text-align: center;
}
.footer-social {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  p {
    text-align: center;
  }
  ul {
    list-style: none;
  }
  img {
    width: 40px;
  }
  li {
    opacity: 0.3;
    cursor: pointer;
    transition: all 0.4s;
  }
  li:hover {
    opacity: 1;
    transform: scale(1.5) translateY(-15%);
  }
}

.footer-logo {
  flex: 0.5;
  .footer-logo-img {
    position: relative;
    left: 50%;
    margin: 50px 0px;
    transform: translateX(-50%);
    // height: 256px;
    width: 128px;
    margin-top: -20px;
  }
}

.footer-info {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .footer-info-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .footer-info-text {
    font-size: 20px;
  }
}
